import React from 'react'
import amplitude from 'amplitude-js'

import MainLayout from '../components/main_layout'
import styles from './about.module.css'

export default class About extends React.Component {
  componentDidMount() {
    amplitude.getInstance().init('b743ec06d753e6f4767b6993243c7b14');
    amplitude.getInstance().logEvent('loadAboutPage');
  }

  render() {
    return (
      <MainLayout>
        <div className={styles.title}>
          About this site
        </div>
        <h1 className={styles.h1}>
          Who made this?
        </h1>
        <p className={styles.paragraph}>
          This site was created by me, <a target='_' href='https://bbirnbaum.com'>Ben Birnbaum</a>,
          as a side project.
          My day job is Senior Director of Software Engineering at Flatiron Health, where I lead the Machine Learning Team. 
          You can learn more about me (and see some other side projects)
          at <a target='_' href='https://bbirnbaum.com'>my homepage</a>.
        </p>
        <h1 className={styles.h1}>
          How does it work?
        </h1>
        <p className={styles.paragraph}>
          First, a disclosure: I’m not an expert, and there are definitely better ways to compute these probabilities. But in the interest of transparency, I wanted to share the details of my methodology, approximate as it is.
        </p>
        <p className={styles.paragraph}>
          If you have any suggestions for how to improve this,
          please <a href='mailto:ben@whenyoumightdie.com'>send me an email</a>.
        </p>
        <h2 className={styles.h2}>
          Data sources
        </h2>
        <p className={styles.paragraph}>
          My primary data source was the <a target='_' href='https://apps.who.int/gho/data/view.main.61780'>Global Health Observatory data repository</a>,
          maintained by the WHO. For each country, I used the “nMx - age-specific death rate between ages x and x+n” data to compute the probability someone of a given age will die in that year. These death rates are bucketed by age group, and I assumed that the death rate was the same for each year in the age group.
        </p>
        <p className={styles.paragraph}>
          The one exception I made to this assumption is the 85+ bucket, since the probability of death goes up rapidly each year for people in their eighties and nineties. I couldn’t find a data source that had fine-grained death rates for octo- and nona-genarians for every country, so instead I
          used <a target='_' href='https://www.cdc.gov/nchs/products/life_tables.htm'>life tables for the U.S. from the CDC</a>.
          I assumed that these probabilities applied to everyone between the ages of 85 and 100, regardless of which country they were in. This is a big assumption, but I didn’t have a good alternative, and it doesn’t affect any probabilities for estimates before age 85 (which is probably what most people care about).
        </p>
        <p className={styles.paragraph}>
          The CDC data only goes up to age 100, and I couldn't find much data for estimating the probability of death each year for people older than 100.
          I found a <a target='_' href='https://science.sciencemag.org/content/360/6396/1459'>study</a> claiming
          that for people who are 105 or older, the probability of death in a given year remains constant at 50%. So for ages 100 to 104, I set the probability of death in a given year as the linear interpolation between the probability of death at 99 (0.32) and the probability of death at 105 (0.5). For ages 105 and older, I set the yearly probability of death to be 0.5.
        </p>
        <h2 className={styles.h2}>
          Simulation
        </h2>
        <p className={styles.paragraph}>
          Once I compiled the data as described above, I had a probability that someone would die in a given year, for each possible country, sex, and age. This is what the website uses to compute probabilities interactively.
        </p>
        <p className={styles.paragraph}>
          Each time the user specifies a year of birth, sex, and country, the website runs 40,000 simulations of that person's life, where each time the person gets one year older, they die with the probability given by the table. A cumulative distribution function is estimated from these simulations, and the first 1000 results are shown in the visualization.
        </p>
      </MainLayout>
    )
  }
}
